@.headerHandler = ->
    $(document).ready(() ->
        menuBtnEvents('.jsHeaderMenuButton', '.jsHeaderMenuContent')
        menuBtnEvents('.jsHeaderMenuUserButton', '.jsHeaderMenuUser')
        productCustomizeMenuEvents('.jsProdCustomizeButton', '.jsHeaderMenuContent')
        productCustomizeMenuEvents('.jsProdCustomizeUserButton', '.jsHeaderMenuUser')
        menuBarEvents()
    )

    menuBtnEvents = (menuButton, typeOfMenu)->
        headerBasket = $('.jsHeaderBasket')
        headerMenuBtn = $(menuButton)
        headerMenuBasket = $(typeOfMenu).find('.jsHeaderMenuBasket')
        headerMenuContent = $(typeOfMenu)
        headerMenuInner = $('.jsHeaderMenuInner')
        headerMenuClose = $(typeOfMenu).find('.jsHeaderMenuClose')

        # Show / hide menu
        headerMenuBtn.on('click', ->
            headerMenuContent.toggleClass('active')
            if (headerMenuContent.hasClass('active'))
                headerMenuInner.css('visibility', 'visible')
                $('body').css('overflow', 'hidden')
            else
                headerMenuInner.css('visibility', 'hidden')
                $('body').css('overflow', 'auto')
        )

        # Hide menu when click outside the menu
        headerMenuInner.on('click', ->
            $(typeOfMenu).removeClass('active')
            headerMenuInner.css('visibility', 'hidden')
            $('body').css('overflow', 'auto')
            $('.jsHeaderMenuUserButton').removeClass('active')
        )

        # Hide menu when click "x"
        headerMenuClose.on('click', ->
            headerMenuBtn.trigger('click')
        )

        # Hide menu when click basket, show basket
        headerMenuBasket.on('click', ->
            headerBasket.trigger('click')
            headerMenuBtn.trigger('click')
        )

    productCustomizeMenuEvents = (menuButton, typeOfMenu)->
        # Show / hide menu
        $(document).on('click', menuButton, ->
            headerMenuContent = $(typeOfMenu)
            headerMenuInner = $('.jsProdCustomizeMenuInner')

            headerMenuContent.toggleClass('active')

            if (headerMenuContent.hasClass('active'))
                headerMenuInner.css('visibility', 'visible')
                $('body').css('overflow', 'hidden')
            else
                headerMenuInner.css('visibility', 'hidden')
                $('body').css('overflow', 'auto')
        )

        # Hide menu when click outside the menu
        $(document).on('click', '.jsProdCustomizeMenuInner', ->
            $(typeOfMenu).removeClass('active')
            $(this).css('visibility', 'hidden')
            $('body').css('overflow', 'auto')
            $('.jsProdCustomizeUserButton').removeClass('active')
        )

        # Hide menu when click "x"
        $(document).on('click', '.jsHeaderMenuClose', ->
            $('.jsProdCustomizeMenuInner').trigger('click')
        )

    # Count random position and width of each bar and darker bar inside
    randomPosition = ->
        randomValue = ->
            return $('.jsHeaderMenuBar').innerWidth()

        minValueWidth = randomValue() * 0.05
        maxValueWidth = randomValue() * 0.2
        minValuePosition = 0
        maxValuePosition = randomValue() * 0.5
        valueWidth = Math.round(Math.random() * (maxValueWidth - minValueWidth + 1) + minValueWidth)
        valuePosition = Math.round(Math.random() * (maxValuePosition - minValuePosition + 1) + minValuePosition)

        return styles = {
            'transform': 'translate3d(' + valuePosition + 'px , 0, 0)',
            'width': valueWidth,
        }

    menuBarEvents = ->
        headerMenuItemText = $('.jsHeaderMenuItemText')
        headerMenuBar = $('.jsHeaderMenuBar')

        # Show item bar and set random position of darker bar inside
        headerMenuItemText.each(() ->
            $(this).on('mouseenter', ->
                $(this).siblings(headerMenuBar).css({
                    transform: 'translate3d(' + $(this).innerWidth() + 'px, -50%, 0)'
#                    left: $(this).innerWidth() + 'px'
                })

                $(this).siblings('.jsHeaderMenuBar').find('.jsHeaderMenuBarDarken').css(randomPosition())
            )

            # Hide item bar
            $(this).on('mouseleave', ->
                $(this).siblings(headerMenuBar).css(transform: 'translate3d(100%, -50%, 0)')
            )
        )
